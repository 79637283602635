import { UnsafeAction as Action } from '../../interfaces';

const ACTION_PREFIX = '[Permission]';

export const permissionActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  UPDATE: `${ACTION_PREFIX} Updated`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,
};

export class PermissionAction {
  static load(): Action {
    return {
      type: permissionActionType.LOAD,
    };
  }

  static loadSuccess(response): Action {
    return {
      type: permissionActionType.LOAD_SUCCESS,
      payload: response,
    };
  }

  static loadFailed(err): Action {
    return {
      type: permissionActionType.LOAD_FAILED,
      payload: err,
    };
  }

  static update(permissions): Action {
    return {
      type: permissionActionType.UPDATE,
      payload: permissions,
    };
  }

  static updateSuccess(permissionResponse): Action {
    return {
      type: permissionActionType.UPDATE_SUCCESS,
      payload: permissionResponse,
    };
  }

  static updateFailed(err): Action {
    return {
      type: permissionActionType.UPDATE_FAILED,
      payload: err,
    };
  }
}
