import { hasAllPermissions, hasPermission } from '../../auth/permission.helper';
import { TeamType } from '../team/team.model';
import { AbsenteePermission, AbsenteePermissionCheckData } from './absence.model';

export const checkAbsenteePermission = (
  absence,
  employeeTeamDepartments,
  permissionState,
  permission: AbsenteePermission,
) => {
  const departments = employeeTeamDepartments[absence.user_id];

  if (!departments?.length) {
    return false;
  }

  const absenteePermissionCheckData = {
    absence,
    departments,
    userId: absence.user_id,
    permissionState,
  };

  switch (permission) {
    case 'view': {
      return checkViewPermissions(absenteePermissionCheckData);
    }
    case 'delete': {
      return checkDeletePermissions(absenteePermissionCheckData);
    }
    case 'approve': {
      return checkApprovePermissions(absenteePermissionCheckData);
    }
    case 'edit': {
      return checkEditPermissions(absenteePermissionCheckData);
    }
  }
};

function checkViewPermissions(data: AbsenteePermissionCheckData) {
  const { userId, permissionState } = data;
  let { departments } = data;

  if (departments && departments.includes(TeamType.FLEXPOOL)) {
    departments = null;
  }
  return hasPermission(
    {
      permissions: ['View absentee', 'View own absentee'],
      userId,
      departments,
    },
    permissionState,
  );
}

function checkDeletePermissions(data: AbsenteePermissionCheckData) {
  const { absence, departments, userId, permissionState } = data;

  if (absence.status === 'Pending') {
    return hasPermission(
      {
        permissions: ['Delete absentee', 'Delete own absentee'],
        userId,
        departments,
      },
      permissionState,
    );
  }

  if (userId !== permissionState.userId) {
    return hasPermission(
      {
        permissions: 'Delete absentee',
        userId,
        departments,
      },
      permissionState,
    );
  }

  if (
    !hasPermission(
      {
        permissions: 'Approve own absentee',
        userId,
        departments,
      },
      permissionState,
    )
  ) {
    return false;
  }

  return hasPermission(
    {
      permissions: ['Delete absentee', 'Delete own absentee'],
      userId,
      departments,
    },
    permissionState,
  );
}

function checkApprovePermissions(data: AbsenteePermissionCheckData) {
  const { departments, userId, permissionState } = data;

  if (userId === permissionState.userId) {
    return hasAllPermissions(
      {
        permissions: ['Approve own absentee', 'Approve absentee'],
        userId,
        departments,
      },
      permissionState,
    );
  }

  return hasPermission(
    {
      permissions: 'Approve absentee',
      userId,
      departments,
    },
    permissionState,
  );
}

function checkEditPermissions(data: AbsenteePermissionCheckData) {
  const { absence, departments, userId, permissionState } = data;

  if (absence.status === 'Pending') {
    return checkPending(absence, departments, userId, permissionState);
  }

  return checkApprovePermissions(data);
}

function checkPending(absence, departments, userId, permissionState) {
  if (
    hasPermission(
      {
        permissions: ['Edit absentee', 'Approve absentee'],
        userId,
        departments,
      },
      permissionState,
    )
  ) {
    return true;
  }

  return hasAllPermissions(
    {
      permissions: ['Edit own absentee', absence.absenteeOption?.permission],
      userId,
      departments,
    },
    permissionState,
  );
}
