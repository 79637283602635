import { Injectable } from '@angular/core';

import { ApiGateway } from '../../../api/ApiGateway.service';
import { ScheduleCopyRequest } from './schedule.model';

@Injectable()
export class ScheduleApi {
  private endpoint = 'schedule/';

  public constructor(private gateway: ApiGateway) {}

  public copy(payload: ScheduleCopyRequest) {
    return this.gateway.post(this.endpoint + 'copy', payload);
  }
}
