import { Injectable } from '@angular/core';
import { compose, Store } from '@ngrx/store';
import { addDays, isValid, parse } from 'date-fns';
import mapValues from 'lodash-es/mapValues';
import sortBy from 'lodash-es/sortBy';
import uniqueId from 'lodash-es/uniqueId';
import { NormalizeOutput } from 'normalizr';
import { createSelector } from 'reselect';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import u from 'updeep';

import { format, periodFilter } from '../../../shared/date.helper';
import { Totals } from '../../../shared/interfaces';
import { defaultTotal, totalAccumulator } from '../../../shared/total.helper';
import { PermissionState } from '../../auth/auth.model';
import { getAuthenticatedUserId } from '../../auth/auth.service';
import { getEmployeesForPeriod, hasPermission } from '../../auth/permission.helper';
import { AppState } from '../../index';
import { getSelectedSchedulePeriod } from '../../page-filters/page-filters.helper';
import { ScheduleFilterPeriod } from '../../page-filters/page-filters.model';
import { mapAndSortEntities, mapEntity } from '../../shared/entity.helper';
import { undo } from '../../shared/undo/undoAction';
import { OptimizedStoreItemData } from '../../store/schedule/schedule.n.model';
import { getAbsences } from '../absence/absence.service';
import { DepartmentModel } from '../department/department.model';
import { getDepartmentEntities } from '../department/department.service';
import { EmployeeModel } from '../employee/employee.model';
import { getEmployeeEntities } from '../employee/employee.service';
import { LocationModel } from '../location/location.model';
import { getLocationEntities } from '../location/location.service';
import { PermissionOption } from '../permission/permission.model';
import { FallbackShift, getShiftEntities, setShiftColor } from '../shift/shift.service';
import { FallbackTeam, getTeamEntities } from '../team/team.service';
import { StrippedEmployeeModel } from './../employee/employee.model';
import { RosterApi } from './roster.api';
import { ScheduleAction } from './schedule.action';
import { ScheduleApi } from './schedule.api';
import { Notify, ScheduleModel, ScheduleScope, SchedulesLoadRequest, ScheduleState } from './schedule.model';

interface CalculateBreakData {
  shift_id: string;
  starttime: string;
  endtime: string;
  break: number | string;
}

@Injectable()
export class ScheduleService {
  public constructor(
    private store: Store<AppState>,
    private rosterApi: RosterApi,
    private scheduleApi: ScheduleApi,
  ) {}

  /**
   * Determine the period to update on a save call
   * @param date
   * @returns {Observable<any>}
   */
  private getSavePeriod(date): Observable<{ minDate: string; maxDate: string }> {
    return this.store.select(getSelectedSchedulePeriod).pipe(
      first(),
      map((filterPeriod: ScheduleFilterPeriod) => ({
        minDate: date,
        maxDate: filterPeriod.maxDate < date ? date : filterPeriod.maxDate,
        viewStartDate: filterPeriod.minDate,
        viewEndDate: filterPeriod.maxDate,
      })),
    );
  }

  public load(requestData: SchedulesLoadRequest, updateStore = true) {
    return this.rosterApi.load(requestData, ScheduleAction.load()).pipe(
      map((response) => {
        if (updateStore) {
          this.store.dispatch(ScheduleAction.loadSuccess(response, requestData));
        }

        return response;
      }),
      catchError((response) => {
        this.store.dispatch(ScheduleAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public add(scheduleData, notify = false): Observable<NormalizeOutput> {
    return this.getSavePeriod(scheduleData.date).pipe(switchMap((period) => this._add(scheduleData, notify, period)));
  }

  private _add(schedule, notify: boolean, period) {
    const loadingId = uniqueId('loading_');

    const action = ScheduleAction.add(schedule, loadingId);

    return this.rosterApi
      .add(
        { Roster: schedule, Notify: notify },
        {
          minDate: period.minDate,
          maxDate: period.maxDate,
        },
        action,
      )
      .pipe(
        tap((response) => {
          const employeeIds = Array.isArray(schedule.user_id) ? [...schedule.user_id] : [schedule.user_id];
          this.store.dispatch(
            ScheduleAction.addSuccess(response, loadingId, { employeeIds, departmentIds: [schedule.department_id] }),
          );
          if (!schedule.user_id) {
            return;
          }
        }),
        catchError((response) => {
          this.store.dispatch(undo(action));
          return observableThrowError(response);
        }),
      );
  }

  public update(occurrenceId, scheduleData, scope: ScheduleScope, notify = false) {
    return this.getSavePeriod(scheduleData.date).pipe(
      switchMap((period) => this._update(occurrenceId, scheduleData, scope, notify, period)),
    );
  }

  private _update(occurrenceId, scheduleData, scope, notify: boolean, period) {
    const loadingId = uniqueId('loading_');

    const action = ScheduleAction.update(scheduleData, occurrenceId, scope, loadingId, scope === 'sequence');

    return this.rosterApi.update(occurrenceId, { Roster: scheduleData, Notify: notify }, period, scope, action).pipe(
      tap((response) => {
        const employeeIds = [];
        if (scheduleData.user_id) {
          employeeIds.push(scheduleData.user_id);
        }

        if (scheduleData.User) {
          employeeIds.push(scheduleData.User);
        }

        this.store.dispatch(
          ScheduleAction.updateSuccess(response, loadingId, scheduleData.user_id, scope === 'sequence', {
            employeeIds,
            departmentIds: [scheduleData.department_id],
          }),
        );
      }),
      catchError((response) => {
        this.store.dispatch(undo(action));

        return observableThrowError(response);
      }),
    );
  }

  public fetch(occurrenceId, dispatchToStore = true) {
    return this.rosterApi.fetch(occurrenceId, ScheduleAction.fetch(occurrenceId)).pipe(
      tap((response) => {
        if (dispatchToStore) {
          this.store.dispatch(ScheduleAction.fetchSuccess(response));
        }
      }),
      catchError((response) => {
        this.store.dispatch(ScheduleAction.fetchFailed(occurrenceId, response));
        return observableThrowError(response);
      }),
    );
  }

  public remove(occurrenceId, notify: Notify, scope: ScheduleScope, optimizedData: OptimizedStoreItemData) {
    const action = ScheduleAction.remove(occurrenceId, scope, optimizedData);

    return this.rosterApi.remove(occurrenceId, notify, scope, action).pipe(
      tap(() => {
        this.store.dispatch(
          ScheduleAction.removeSuccess({
            employeeIds: [optimizedData.userId],
            departmentIds: [optimizedData.departmentId],
          }),
        );
      }),
      catchError((response) => {
        this.store.dispatch(undo(action));
        return observableThrowError(response);
      }),
    );
  }

  public save(scheduleData, scope?: ScheduleScope) {
    if (scheduleData.id) {
      return this.update(scheduleData.id, scheduleData, scope);
    }

    scheduleData = u.omit('id', scheduleData);

    return this.add(scheduleData);
  }

  public calculateBreak(breakData: CalculateBreakData): Observable<number> {
    return this.rosterApi.calculateBreak(breakData).pipe(map((respone) => parseInt(respone.break, 10)));
  }

  public send(sendData) {
    return this.rosterApi.send(sendData).pipe(catchError((response) => observableThrowError(response)));
  }

  public checkTotal(shiftData) {
    return this.rosterApi.checkTotal(shiftData);
  }

  public copy(requestData) {
    return this.scheduleApi.copy(requestData);
  }
}

export const sortSchedules = (schedules: ScheduleModel[]) => sortBy(schedules, ['date', 'starttime']);
export const mapAndSortSchedules = mapAndSortEntities(sortSchedules);

export const getScheduleState = (appState: AppState): ScheduleState => appState.orm.schedules;

export const getScheduleIds = compose((state) => state.items, getScheduleState);

export const getScheduleEntities = compose((state) => state.itemsById, getScheduleState);

export const enhanceSchedule =
  (teamEntities, shiftEntities, departmentEntities, locationEntities) => (schedule: ScheduleModel) => {
    const team = teamEntities[schedule.team_id] ? teamEntities[schedule.team_id] : FallbackTeam;

    let shift;

    if (shiftEntities[schedule.shift_id]) {
      shift = shiftEntities[schedule.shift_id];
    } else {
      const fallbackShift = {
        ...setShiftColor(FallbackShift, schedule.color),
        long_name: schedule.name,
        is_task: schedule.is_task,
      };
      shift = fallbackShift;
    }

    const departmentId = schedule.department_id || team.department_id || shift.department_id;
    const department = departmentEntities[departmentId] ? departmentEntities[departmentId] : ({} as DepartmentModel);

    let departmentName = department && department.name ? department.name : '';
    if (departmentName === '' && schedule.Department && schedule.Department.name) {
      departmentName = schedule.Department.name;
    }

    const locationId = department.location_id;
    const location =
      locationId && !!locationEntities[locationId] ? locationEntities[locationId] : ({} as LocationModel);
    let locationName = location && location.name ? location.name : '';

    if (
      locationName === '' &&
      schedule.Department &&
      schedule.Department['Location'] &&
      schedule.Department['Location'].name
    ) {
      locationName = schedule.Department['Location'].name;
    }

    let startDateTime = parse(schedule.date + ' ' + schedule.starttime, 'yyyy-MM-dd HH:mm:ss', new Date());
    if (!isValid(startDateTime)) {
      startDateTime = parse(schedule.date + ' ' + schedule.starttime, 'yyyy-MM-dd HH:mm', new Date());
    }

    let endDateTime = parse(schedule.date + ' ' + schedule.endtime, 'yyyy-MM-dd HH:mm:ss', new Date());
    if (!isValid(endDateTime)) {
      endDateTime = parse(schedule.date + ' ' + schedule.endtime, 'yyyy-MM-dd HH:mm', new Date());
    }

    if (endDateTime <= startDateTime) {
      endDateTime = addDays(endDateTime, 1);
    }

    return {
      ...schedule,
      Team: team,
      Shift: shift,
      department_name: departmentName,
      location_name: locationName,
      startDateTime,
      endDateTime,
    };
  };

export const getEnhancedScheduleEntities = createSelector(
  getScheduleState,
  getTeamEntities,
  getShiftEntities,
  getDepartmentEntities,
  getLocationEntities,
  (state, teamEntities, shiftEntities, departmentEntities, locationEntities) =>
    mapValues(state.itemsById, (schedule) =>
      enhanceSchedule(teamEntities, shiftEntities, departmentEntities, locationEntities)(schedule),
    ),
);
export const getEnhancedSchedules = createSelector(getScheduleIds, getEnhancedScheduleEntities, mapAndSortSchedules);
export const getSchedules = createSelector(getScheduleIds, getScheduleEntities, mapAndSortSchedules);

export const getEnhancedSchedule = (occurrenceId: string) =>
  createSelector(getEnhancedScheduleEntities, getEmployeeEntities, (entities, employeeEntities) => {
    const schedule: ScheduleModel = mapEntity(occurrenceId, entities);

    if (!schedule || !schedule.created_by) {
      return schedule;
    }

    const createdBy = mapEntity(schedule.created_by, employeeEntities);
    const modifiedBy = mapEntity(schedule.modified_by, employeeEntities);
    return {
      ...schedule,
      CreatedBy: strippedEmployee(createdBy),
      ModifiedBy: strippedEmployee(modifiedBy),
    };
  });

const strippedEmployee = (employee: EmployeeModel): StrippedEmployeeModel => {
  if (!employee) {
    return undefined;
  }

  return {
    id: employee.id,
    name: employee.name,
  };
};

export const getSchedulesForAuthenticatedUser = createSelector(
  getAuthenticatedUserId,
  getEnhancedSchedules,
  (userId: string, schedules: ScheduleModel[]) => schedules.filter((schedule) => schedule.user_id === userId),
);

export const getSchedulesForAuthenticatedUserWithinPeriod = (minDate, maxDate) =>
  createSelector(getSchedulesForAuthenticatedUser, (schedules) => schedules.filter(periodFilter(minDate, maxDate)));

export const getSchedulesWithinPeriod = (minDate, maxDate) =>
  createSelector(getSchedules, (schedules) => schedules.filter(periodFilter(minDate, maxDate)));

export const getScheduledEmployeesForPeriod = (
  permissions,
  minDate: string = format(new Date(), 'yyyy-MM-dd'),
  maxDate: string = format(new Date(), 'yyyy-MM-dd'),
) =>
  createSelector(
    getEmployeesForPeriod(permissions, minDate, maxDate),
    getSchedulesWithinPeriod(minDate, maxDate),
    (employees: EmployeeModel[], schedules: ScheduleModel[]): Set<string> => {
      const employeeIds: string[] = employees.map((employee) => employee.id);
      const scheduleUserIds: string[] = schedules.map((schedule) => schedule.user_id);
      return new Set(scheduleUserIds.filter((value) => -1 !== employeeIds.indexOf(value)));
    },
  );

export const getSchedulesForUser = (userId: string) =>
  createSelector(
    getTeamEntities,
    getShiftEntities,
    getDepartmentEntities,
    getLocationEntities,
    getSchedules,
    getAbsences,
    (teamEntities, shiftEntities, departmentEntities, locationEntities, schedules: ScheduleModel[]) =>
      schedules
        .filter((schedule) => schedule.user_id === userId)
        .map(enhanceSchedule(teamEntities, shiftEntities, departmentEntities, locationEntities)),
  );

export const hasSchedulePermission =
  (permissions: PermissionOption, permissionState: PermissionState) => (schedule: ScheduleModel) => {
    const check = {
      permissions,
      userId: schedule.user_id,
      departments: schedule.department_id,
    };

    return hasPermission(check, permissionState);
  };

export const sumSchedules = (schedules: ScheduleModel[]): Totals => {
  if (!schedules || schedules.length === 0) {
    return defaultTotal;
  }

  return schedules.reduce((acc, schedule: ScheduleModel) => {
    const scheduleTotal = {
      hours: 0,
      pay: 0,
    };

    if (!schedule.Shift.is_task) {
      scheduleTotal.hours = parseFloat(schedule.total);
      scheduleTotal.pay = parseFloat(schedule.salary);
    }

    return totalAccumulator(acc, scheduleTotal);
  }, defaultTotal);
};
