import { UnsafeAction as Action } from '../../interfaces';
import { OptimizedStoreItemData } from '../../store/schedule/schedule.n.model';
import { LoadSchedulesSuccessAction, ScheduleScope, SchedulesLoadRequest, ScheduleUpdateEvent } from './schedule.model';

const ACTION_PREFIX = '[Schedule]';

export const scheduleActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  LOAD_SCHEDULE_DATASET_SUCCESS: `${ACTION_PREFIX} Load Schedule Dataset Success`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,

  FETCH: `${ACTION_PREFIX} Fetch`,
  FETCH_SUCCESS: `${ACTION_PREFIX} Fetch Success`,
  FETCH_FAILED: `${ACTION_PREFIX} Fetch Failed`,

  REMOVE: `${ACTION_PREFIX} Remove`,
  REMOVE_SUCCESS: `${ACTION_PREFIX} Remove Success`,
};

export class ScheduleAction {
  public static load(): Action {
    return {
      type: scheduleActionType.LOAD,
    };
  }

  public static loadSuccess(schedule, requestData: SchedulesLoadRequest): LoadSchedulesSuccessAction {
    return {
      type: scheduleActionType.LOAD_SUCCESS,
      payload: schedule,
      requestData,
    };
  }

  public static loadFailed(err): Action {
    return {
      type: scheduleActionType.LOAD_FAILED,
      payload: err,
    };
  }

  public static loadScheduleDatasetSuccess(data, requestData = null): Action {
    return {
      type: scheduleActionType.LOAD_SCHEDULE_DATASET_SUCCESS,
      payload: data,
      requestData: requestData,
    };
  }

  public static add(scheduleData, loadingId): Action {
    return {
      type: scheduleActionType.ADD,
      payload: { scheduleData, loadingId },
    };
  }

  public static addSuccess(scheduleResponse, loadingId, updateEvent: ScheduleUpdateEvent): Action {
    return {
      type: scheduleActionType.ADD_SUCCESS,
      payload: { ...scheduleResponse, loadingId },
      updateEvent,
    };
  }

  public static update(scheduleData, occurrenceId, scope: ScheduleScope, loadingId, recurring = false): Action {
    return {
      type: scheduleActionType.UPDATE,
      payload: { scheduleData, occurrenceId, scope, loadingId, recurring },
    };
  }

  public static updateSuccess(
    scheduleResponse,
    loadingId,
    userId: string,
    recurring = false,
    updateEvent: ScheduleUpdateEvent,
  ): Action {
    return {
      type: scheduleActionType.UPDATE_SUCCESS,
      payload: { ...scheduleResponse, loadingId, userId, recurring },
      updateEvent,
    };
  }

  public static fetch(id: string) {
    return {
      type: scheduleActionType.FETCH,
      payload: id,
    };
  }

  public static fetchSuccess(response) {
    return {
      type: scheduleActionType.FETCH_SUCCESS,
      payload: response,
    };
  }

  public static fetchFailed(id: string, err) {
    return {
      type: scheduleActionType.FETCH_FAILED,
      payload: {
        id,
        err,
      },
    };
  }

  public static remove(occurrenceId: string, scope: ScheduleScope, optimizedData?: OptimizedStoreItemData) {
    return {
      type: scheduleActionType.REMOVE,
      payload: { occurrenceId, scope, optimizedData },
    };
  }

  public static removeSuccess(updateEvent: ScheduleUpdateEvent) {
    return {
      type: scheduleActionType.REMOVE_SUCCESS,
      updateEvent,
    };
  }
}
