import { AbsenceOptionUnit } from '@app/enums';
import { addDays, addMinutes, addYears, format, parse, subDays, subYears } from 'date-fns';

import { removeSecondsFromTime } from '../../../shared/date.helper';
import { ScheduleModel } from '../schedule/schedule.model';
import { TimesheetModel } from '../timesheet/timesheet.model';
import { OpenShiftModel } from './../open-shift/open-shift.model';
import { RequiredShiftModel } from './../required-shift/required-shift.model';
import {
  AbsenceConflictOptions,
  AbsenceModel,
  AbsenceWithPeriodSumModel,
  AbsenteeDay,
  EnhancedAbsenceModel,
} from './absence.model';

export const absenceInPeriod = (minDate: string, maxDate: string) => (absence: AbsenceModel | EnhancedAbsenceModel) => {
  if (absence.deleted) {
    return false;
  }

  if (absence.startdate > maxDate) {
    return false;
  }

  if (absence.enddate < minDate) {
    return false;
  }

  return true;
};

export const absenceInDateRange = (minDate: Date, maxDate: Date) => (absence: AbsenceModel | EnhancedAbsenceModel) => {
  if (absence.deleted) {
    return false;
  }

  if (Object.hasOwn(absence, 'startDateTime') && Object.hasOwn(absence, 'endDateTime')) {
    if (absence.startDateTime > maxDate || absence.endDateTime < minDate) {
      return false;
    }
  } else {
    // TODO probably dead (and incorrect) code, but in the interest of not breaking anything, I'll leave these broken types alone for now
    const startDateTime = parse(absence.date + ' ' + absence.starttime, 'yyyy-MM-dd HH:mm:ss', new Date());
    let endDateTime = parse(absence.date + ' ' + absence.endtime, 'yyyy-MM-dd HH:mm:ss', new Date());

    if (endDateTime <= startDateTime) {
      endDateTime = addDays(endDateTime, 1);
    }
    if (startDateTime > maxDate || endDateTime < minDate) {
      return false;
    }
  }

  return true;
};

export const absenceInPeriodOrTimeRangeForTimesheet =
  (timesheet: TimesheetModel) => (absence: AbsenceModel | EnhancedAbsenceModel) => {
    const startDate = parse(
      timesheet.date + ' ' + removeSecondsFromTime(timesheet.starttime),
      'yyyy-MM-dd HH:mm',
      new Date(),
    );
    const endDate = timesheet.endtime
      ? parse(timesheet.date + ' ' + removeSecondsFromTime(timesheet.endtime), 'yyyy-MM-dd HH:mm', new Date())
      : null;
    return isAbsenceInPeriodOrTimeRange(timesheet.date, startDate, endDate)(absence);
  };

export const absenceInPeriodOrTimeRange =
  (schedule: ScheduleModel | OpenShiftModel | RequiredShiftModel) => (absence: AbsenceModel | EnhancedAbsenceModel) =>
    isAbsenceInPeriodOrTimeRange(schedule.date, schedule.startDateTime, schedule.endDateTime)(absence);

export const isAbsenceInPeriodOrTimeRange =
  (date: string, startTime: Date, endTime?: Date) => (absence: AbsenceModel) => {
    if (absence.deleted) {
      return false;
    }

    if (absence.startdate > date || absence.enddate < date) {
      return false;
    }

    const absenteeDay = absence.AbsenteeDay[date];
    if (!absenteeDay || absenteeDay.hidden) {
      return false;
    }

    if (absenteeDay.partial_day && startTime && endTime) {
      return absenteeDay.startDate < endTime && absenteeDay.endDate > startTime;
    }

    return true;
  };

export const checkAbsenceConflict =
  (date: string, options: AbsenceConflictOptions, absenceTypeId?: string) =>
  (absence: EnhancedAbsenceModel | AbsenceWithPeriodSumModel): boolean => {
    if (absence.deleted) {
      return false;
    }
    if (absence.startdate > date || absence.enddate < date) {
      return false;
    }
    if (absenceTypeId && absence.absentee_option_id !== absenceTypeId) {
      return false;
    }
    const absenteeDay = absence.AbsenteeDay[date];
    if (!absenteeDay) {
      return false;
    }
    // Validation for 2 partial days off on the same day
    if (absenteeDay.partial_day && options.partialDay) {
      return checkPartialDayConflict(date, options, absenteeDay);
    }
    return true;
  };

const checkPartialDayConflict = (date: string, options: AbsenceConflictOptions, absenteeDay: AbsenteeDay): boolean => {
  // Request in hours
  if (options.startTime && options.hours) {
    const startDate = dateFromDateAndTime(date, options.startTime);
    const minutes = (parseFloat(options.hours) * 60).toString();
    const endDate = addMinutes(startDate, parseInt(minutes, 10));
    return absenteeDay.startDate < endDate && absenteeDay.endDate > startDate;
  }
  // Request in days from time
  if (options.fromTime) {
    const startDate = dateFromDateAndTime(date, options.fromTime);
    return absenteeDay.endDate > startDate;
  }
  // Request in days until time
  if (options.untilTime) {
    const endDate = dateFromDateAndTime(date, options.untilTime);
    return absenteeDay.startDate < endDate;
  }
  return true;
};

const dateFromDateAndTime = (date: string, time: string): Date =>
  parse(`${date} ${removeSecondsFromTime(time)}`, 'yyyy-MM-dd HH:mm', new Date());

// Checks if the absence is hidden without value
export const isAbsenceWithoutValueHidden = (absence: AbsenceModel, day: AbsenteeDay): boolean => {
  if (absence.status === 'Approved' && absence.hide_days_without_hours) {
    const absenceUnit = absence.absence_unit || AbsenceOptionUnit.HOURS;
    if (absenceUnit === AbsenceOptionUnit.DAYS) {
      return parseFloat(day.days) === 0;
    }
    return parseFloat(day.hours) === 0;
  }
  return false;
};

export const defaultAbsencesDateRange = {
  minDate: format(subYears(new Date(), 1), 'yyyy-MM-dd'),
  // API gives an error for exactly 5 years from now so we have to subtract 1 day
  maxDate: format(subDays(addYears(new Date(), 5), 1), 'yyyy-MM-dd'),
};
