import { Injectable } from '@angular/core';
import { compose, Store } from '@ngrx/store';
import groupBy from 'lodash-es/groupBy';
import orderBy from 'lodash-es/orderBy';
import { createSelector } from 'reselect';
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  getPermissionState as getPermissionCheckData,
  hasPermission,
  PermissionCheck,
} from '../../auth/permission.helper';
import { AppState } from '../../index';
import { mapEntities, mapEntity } from '../../shared/entity.helper';
import { PermissionAction } from './permission.action';
import { PermissionApi } from './permission.api';
import { PermissionState } from './permission.model';

@Injectable()
export class PermissionService {
  constructor(
    private store: Store<AppState>,
    private api: PermissionApi,
  ) {}

  load() {
    this.store.dispatch(PermissionAction.load());

    return this.api.load().pipe(
      map((response) => {
        this.store.dispatch(PermissionAction.loadSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(PermissionAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  savePermissions(permissions: { [permissionId: string]: string[] }) {
    this.store.dispatch(PermissionAction.update(permissions));

    return this.api.savePermisions(permissions).pipe(
      map((response) => {
        this.store.dispatch(PermissionAction.updateSuccess(response));
        return response;
      }),
      catchError((err) => {
        this.store.dispatch(PermissionAction.updateFailed(err));
        return observableThrowError(err);
      }),
    );
  }
}

export const getPermissionState = (appState: AppState): PermissionState => appState.orm.permissions;

export const getPermissionIds = compose((state) => state.items, getPermissionState);
export const getPermissionEntities = createSelector(getPermissionState, (state) => state.itemsById);
export const getPermissions = createSelector(getPermissionIds, getPermissionEntities, (ids, permissions) =>
  orderBy(mapEntities(ids, permissions), (permission: any) => parseInt(permission.weight, 10), ['asc']),
);

export const getPermission = (id: string) =>
  createSelector(getPermissionEntities, (entities) => mapEntity(id, entities));

export const groupPermissionsByModule = (permissions) => groupBy(permissions, 'module_id');
export const getAllPermissionsByModule = createSelector(getPermissions, groupPermissionsByModule);

export const doPermissionCheck = (check: PermissionCheck) =>
  createSelector(getPermissionCheckData, (permissionCheckData) => hasPermission(check, permissionCheckData));
