/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { compose, Store } from '@ngrx/store';
import { addDays, addMinutes, addYears, endOfDay, getTime, isSameDay, parse, startOfDay, subYears } from 'date-fns';
import filter from 'lodash-es/filter';
import keyBy from 'lodash-es/keyBy';
import mapValues from 'lodash-es/mapValues';
import pickBy from 'lodash-es/pickBy';
import reduce from 'lodash-es/reduce';
import sortBy from 'lodash-es/sortBy';
import { createSelector } from 'reselect';
import { Observable, of as observableOf, throwError as observableThrowError, Subject } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import u from 'updeep';

import { dayList, format, periodFilter } from '../../../shared/date.helper';
import { Totals } from '../../../shared/interfaces';
import { defaultTotal, totalAccumulator } from '../../../shared/total.helper';
import {
  getEmployeeTeamDepartments,
  getEmployeeTeamDepartmentsWithoutFlexpool,
  getPermissionState,
  PermissionCheck,
} from '../../auth/permission.helper';
import { AppState } from '../../index';
import { getSelectedDepartmentIds } from '../../selected-departments/selected-departments.service';
import { mapAndSortEntities, mapEntity } from '../../shared/entity.helper';
import { getAbsenteeOptionEntities } from '../absentee-option/absentee-option.service';
import { getEmployeeEntities } from '../employee/employee.service';
import { PermissionOption } from '../permission/permission.model';
import { doPermissionCheck } from '../permission/permission.service';
import { AbsenceAction } from './absence.action';
import { AbsenceApi } from './absence.api';
import { absenceInDateRange, absenceInPeriod, isAbsenceWithoutValueHidden } from './absence.helper';
import {
  AbsenceExpectedRequest,
  AbsenceExpectedResponse,
  AbsenceInfoRequest,
  AbsenceInfoResponse,
  AbsenceLoadRequest,
  AbsenceMarkAsReturnRequest,
  AbsenceModel,
  AbsenceState,
  AbsenceStatus,
  AbsenceWithPeriodSumModel,
  AbsenteeDay,
  BulkUpdateAbsenceRequest,
  EnhancedAbsenceModel,
  KeyedAbsenteeDays,
} from './absence.model';
import { checkAbsenteePermission } from './absence.permission.helper';

@Injectable()
export class AbsenceService {
  private _changed$ = new Subject();
  public readonly viewPerms: PermissionOption = ['View absentee', 'View own absentee'];

  public constructor(
    private store: Store<AppState>,
    private api: AbsenceApi,
  ) {}

  private canView() {
    const check: PermissionCheck = {
      permissions: this.viewPerms,
      userId: 'me',
      departments: 'any',
    };

    return this.store.select(doPermissionCheck(check)).pipe(take(1));
  }

  public load(requestData: AbsenceLoadRequest, updateStore = true) {
    return this.canView().pipe(
      mergeMap((hasPermission) => (hasPermission ? this._load(requestData, updateStore) : observableOf([]))),
    );
  }

  private _load(requestData: AbsenceLoadRequest, updateStore) {
    return this.api.load(requestData, AbsenceAction.load(requestData)).pipe(
      map((response) => {
        if (updateStore) {
          this.store.dispatch(AbsenceAction.loadSuccess(requestData, response));
        }

        return response;
      }),
      catchError((response) => {
        this.store.dispatch(AbsenceAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public add(absenceData): Observable<any> {
    return this.api.add(absenceData, AbsenceAction.add(absenceData)).pipe(
      map((response) => {
        this.store.dispatch(AbsenceAction.addSuccess(response));
        this._changed$.next(1);
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(AbsenceAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public update(id, absenceData) {
    return this.api.update(id, absenceData, AbsenceAction.update(absenceData)).pipe(
      map((response) => {
        this.store.dispatch(AbsenceAction.updateSuccess(response));
        this._changed$.next(1);

        return response;
      }),
      catchError((response) => {
        this.store.dispatch(AbsenceAction.updateFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public fetch(id, dispatchToStore = true) {
    return this.canView().pipe(
      mergeMap((hasPermission) => (hasPermission ? this._fetch(id, dispatchToStore) : observableOf(false))),
    );
  }

  private _fetch(id, dispatchToStore = true) {
    return this.api.fetch(id, AbsenceAction.fetch(id)).pipe(
      tap((response) => {
        if (dispatchToStore) {
          this.store.dispatch(AbsenceAction.fetchSuccess(response));
        }
      }),
      catchError((response) => {
        this.store.dispatch(AbsenceAction.fetchFailed(id, response));
        return observableOf(false);
      }),
    );
  }

  public remove(id: string, userId: string, absenteeDays: Dictionary<AbsenteeDay>) {
    const departmentIds: string[] = [
      ...new Set(Object.values(absenteeDays ?? {}).map((absenteeDay) => absenteeDay.department_id)),
    ];
    return this.api.remove(id, AbsenceAction.remove(id, userId, departmentIds)).pipe(
      map((response) => {
        this.store.dispatch(AbsenceAction.removeSuccess(id));
        this._changed$.next(1);

        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(AbsenceAction.removeFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public save(absenceData) {
    if (absenceData.id) {
      return this.update(absenceData.id, absenceData);
    }

    absenceData = u.omit('id', absenceData);

    return this.add(absenceData);
  }

  public getExpected(absence: AbsenceExpectedRequest): Observable<AbsenceExpectedResponse> {
    return this.api.getExpected(absence);
  }

  public listenToChanges() {
    return this._changed$;
  }

  public getInfo(requestData: AbsenceInfoRequest): Observable<AbsenceInfoResponse> {
    return this.api.getInfo(requestData);
  }

  public bulkUpdateAbsentees(absenceRequest: BulkUpdateAbsenceRequest): Observable<{ Absentee: AbsenceModel }[]> {
    return this.api.bulkUpdateAbsentees(absenceRequest);
  }

  // TODO this should really just be handled through `save`
  // but backend insists on having a separate endpoint for this
  // instead of sticking to proper RESTful principles
  public markAsReturned(requestData: AbsenceMarkAsReturnRequest) {
    return this.api.markAsReturned(requestData, AbsenceAction.update(requestData)).pipe(
      map((response) => {
        this.store.dispatch(AbsenceAction.updateSuccess(response));
        this._changed$.next(1);

        return response;
      }),
      catchError((response) => {
        this.store.dispatch(AbsenceAction.updateFailed(requestData.id, response));
        throw new Error(response);
      }),
    );
  }
}

export const sortAbsence = (absence: AbsenceModel[]): AbsenceModel[] => sortBy(absence, ['startdate', 'created']);
export const mapAndSortAbsence = mapAndSortEntities(sortAbsence);

export const getAbsenceState = (appState: AppState): AbsenceState => appState.orm.absence;

export const getAbsenceIds = compose((state) => state.items, getAbsenceState);

export const getAbsenceEntities = createSelector(getAbsenceState, (state) =>
  mapValues(state.itemsById, (absence: AbsenceModel) => {
    const startDateTime = parse(absence.startdate + ' ' + absence.start_time, 'yyyy-MM-dd HH:mm:ss', new Date());
    let endDateTime = parse(absence.enddate + ' ' + absence.end_time, 'yyyy-MM-dd HH:mm:ss', new Date());

    if (absence.partial_day && endDateTime < startDateTime) {
      endDateTime = addDays(endDateTime, 1);
    }
    if (!absence.partial_day) {
      endDateTime = endOfDay(endDateTime);
    }

    const returnValue = {
      ...absence,
      startDateTime,
      endDateTime,
    };

    if (absence.AbsenteeDay) {
      /*
        Why sort here and not in the reducer?
        Because the mergeEntities function used in the reducer
        uses under the hood the updeep library which does not
        guarantee sorting order for keyed properties of objects.
        Therefore sorting in the reducer and then calling mergeEntities
        will invalidate sort order. Since this is not a heavy action,
        this can be performed here.
      */

      const sortedAbsenteeDays = {};
      Object.keys(absence.AbsenteeDay)
        .sort()
        .forEach((key) => {
          sortedAbsenteeDays[key] = absence.AbsenteeDay[key];
        });

      returnValue.AbsenteeDay = sortedAbsenteeDays;
    }

    return returnValue;
  }),
);

export const getAbsences = createSelector(getAbsenceIds, getAbsenceEntities, mapAndSortAbsence);

export const getAbsenceEnhanced = createSelector(
  getAbsences,
  getEmployeeEntities,
  getAbsenteeOptionEntities,
  getEmployeeTeamDepartments,
  getPermissionState,
  (absence: AbsenceModel[], employees, absenteeOptions, employeeTeamDepartments, permissionState) =>
    absence.map((absentee: AbsenceModel) =>
      enhanceAbsence(absentee, employees, absenteeOptions, employeeTeamDepartments, permissionState),
    ),
);

export const getAbsencesForUser = (userId: string) =>
  createSelector(getAbsenceEnhanced, (absences: EnhancedAbsenceModel[]) =>
    absences.filter((absence) => absence.user_id === userId),
  );

export const getAbsenceById = (id: string) => createSelector(getAbsenceEntities, (entities) => mapEntity(id, entities));

export const getEnhancedAbsenceById = (id: string) =>
  createSelector(
    getAbsenceEntities,
    getEmployeeEntities,
    getAbsenteeOptionEntities,
    getEmployeeTeamDepartments,
    getPermissionState,
    (entities, employees, absenteeOptions, employeeTeamDepartments, permissionState) => {
      const absence = mapEntity(id, entities);

      if (!absence) {
        return absence;
      }

      return enhanceAbsence(absence, employees, absenteeOptions, employeeTeamDepartments, permissionState);
    },
  );

export const getUserAbsence = (userId: string, minDate: string, maxDate: string) =>
  createSelector(getAbsenceEnhanced, (absence: EnhancedAbsenceModel[]) => {
    const absencePeriodFilter = absenceInPeriod(minDate, maxDate);

    return absence.filter((absentee) => {
      if (!absencePeriodFilter(absentee)) {
        return false;
      }

      return absentee.user_id === userId;
    });
  });

export function filterPendingAbsences(absences: EnhancedAbsenceModel[]) {
  return filter(absences, (absence) => absence.status === 'Pending');
}

export function filterReviewedAbsences(absences: EnhancedAbsenceModel[]) {
  return filter(absences, (absence) => absence.status !== 'Pending');
}

function enhanceAbsence(
  absentee: AbsenceModel,
  employees,
  absenteeOptions,
  employeeTeamDepartments,
  permissionState,
): EnhancedAbsenceModel {
  const absence = {
    ...absentee,
    Employee: employees[absentee.user_id],
    absenteeOption: absenteeOptions[absentee.absentee_option_id],
  };

  const canEdit = checkAbsenteePermission(absence, employeeTeamDepartments, permissionState, 'edit');
  const canDelete = checkAbsenteePermission(absence, employeeTeamDepartments, permissionState, 'delete');
  const canApprove = checkAbsenteePermission(absence, employeeTeamDepartments, permissionState, 'approve');

  return {
    ...absence,
    canEdit,
    canDelete,
    canApprove,
  };
}

export const filterAndSumAbsenteeDays = (absence: AbsenceModel, filterFn): AbsenceWithPeriodSumModel => {
  const absenteeDays = absence['AbsenteeDay'];
  const daysFilteredForPeriod = pickBy(absenteeDays, filterFn) as KeyedAbsenteeDays;
  const daysFilteredForHours = pickBy(daysFilteredForPeriod, (absenteeDay) => !absenteeDay.hidden) as KeyedAbsenteeDays;

  const total = reduce(
    daysFilteredForHours,
    (acc, day: AbsenteeDay) => {
      const calcData = {
        hours: parseFloat(day.hours),
        pay: parseFloat(day.salary),
      };

      return totalAccumulator(acc, calcData);
    },
    defaultTotal,
  );

  return {
    ...absence,
    AbsenteeDay: daysFilteredForHours,
    periodHours: total.hours,
    periodPay: total.pay,
  };
};

export const absenceForPeriod = (
  minDate: string,
  maxDate: string,
  permissionState,
  employeeTeamDepartments,
  absences: AbsenceModel[],
): AbsenceModel[] => {
  const absencePeriodFilter = absenceInPeriod(minDate, maxDate);

  return absences
    .filter((absenceRow) => absencePeriodFilter(absenceRow))
    .filter((absenceRow) => checkAbsenteePermission(absenceRow, employeeTeamDepartments, permissionState, 'view'))
    .map((absenceRow: AbsenceModel) => {
      const canEdit = checkAbsenteePermission(absenceRow, employeeTeamDepartments, permissionState, 'edit');
      const canDelete = checkAbsenteePermission(absenceRow, employeeTeamDepartments, permissionState, 'delete');

      const parsedMinDate = parse(minDate, 'yyyy-MM-dd', new Date());
      const parsedMaxDate = endOfDay(parse(maxDate, 'yyyy-MM-dd', new Date()));

      const daysFilteredForHours = pickBy(absenceRow.AbsenteeDay, (absenteeDay) => {
        if (absenteeDay.hidden) {
          return false;
        }

        if (getTime(parsedMinDate) === getTime(parsedMaxDate)) {
          if (!isSameDay(parsedMinDate, absenteeDay.startDate) && !isSameDay(parsedMinDate, absenteeDay.endDate)) {
            return false;
          }
        } else {
          if (absenteeDay.startDate > parsedMaxDate || absenteeDay.endDate < parsedMinDate) {
            return false;
          }
        }

        return true;
      }) as KeyedAbsenteeDays;

      return {
        ...absenceRow,
        AbsenteeDay: daysFilteredForHours,
        canEdit,
        canDelete,
      };
    })
    .filter((absenceRow) => {
      const days = Object.values(absenceRow.AbsenteeDay);
      return days.length > 0;
    });
};

export const absenceForDateRange = (
  minDate: Date,
  maxDate: Date,
  permissionState,
  employeeTeamDepartments,
  absence: AbsenceModel[],
): AbsenceModel[] => {
  const absencePeriodFilter = absenceInDateRange(minDate, maxDate);

  return absence
    .filter((absenceRow) => absencePeriodFilter(absenceRow))
    .filter((absenceRow) => checkAbsenteePermission(absenceRow, employeeTeamDepartments, permissionState, 'view'))
    .map((absenceRow: AbsenceModel) => {
      const canEdit = checkAbsenteePermission(absenceRow, employeeTeamDepartments, permissionState, 'edit');
      const canDelete = checkAbsenteePermission(absenceRow, employeeTeamDepartments, permissionState, 'delete');

      const daysFilteredForHours = pickBy(absenceRow.AbsenteeDay, (absenteeDay) => {
        if (absenteeDay.hidden) {
          return false;
        }

        if (absenteeDay.partial_day) {
          if (absenteeDay.startDate > maxDate || absenteeDay.endDate < minDate) {
            return false;
          }
        } else {
          const sameMinDate = isSameDay(minDate, absenteeDay.startDate);
          const sameMaxDate = isSameDay(maxDate, absenteeDay.startDate);

          if (!sameMinDate && !sameMaxDate) {
            return false;
          }
        }

        return true;
      }) as KeyedAbsenteeDays;

      return {
        ...absenceRow,
        AbsenteeDay: daysFilteredForHours,
        canEdit,
        canDelete,
      };
    })
    .filter((absenceRow) => {
      const days = Object.values(absenceRow.AbsenteeDay);
      return days.length > 0;
    });
};

export const absenceForPeriodWithSum = (
  minDate: string,
  maxDate: string,
  permissionState,
  employeeTeamDepartments,
  absence: AbsenceModel[],
) => {
  const detailFilter = periodFilter(minDate, maxDate);

  return absenceForPeriod(minDate, maxDate, permissionState, employeeTeamDepartments, absence)
    .filter((absenceRow) => absenceRow.status !== 'Declined')
    .map((absenceRow) =>
      //filter absence days for period
      filterAndSumAbsenteeDays(absenceRow, detailFilter),
    );
};

export const absenceForDayWithPeriodSum = (day: string, absence: AbsenceModel[]) => {
  const detailFilter = periodFilter(day, day);

  return absence
    .filter(absenceInPeriod(day, day))
    .map((absenceRow) => filterAndSumAbsenteeDays(absenceRow, detailFilter));
};

export const absenceForDay = (day: string, absence: AbsenceModel[]) => absence.filter(absenceInPeriod(day, day));

/**
 * Split absence into absence per day
 * @param minDate
 * @param maxDate
 * @param absence
 */
export const absenceForPeriodSplitIntoDays = (
  minDate: string,
  maxDate: string,
  permissionState,
  employeeTeamDepartments,
  absences: AbsenceModel[],
) => {
  const days = keyBy(dayList(minDate, maxDate), (day) => day);
  absences = absenceForPeriod(minDate, maxDate, permissionState, employeeTeamDepartments, absences);

  return mapValues(days, (day) => absenceForDayWithPeriodSum(day, absences));
};

export const sumPeriodAbsence = (absence: AbsenceWithPeriodSumModel[]): Totals => {
  if (!absence || absence.length === 0) {
    return defaultTotal;
  }

  return absence.reduce((acc, model: AbsenceWithPeriodSumModel) => {
    if (model.status !== 'Approved') {
      return acc;
    }

    const modelTotal = {
      hours: model.periodHours,
      pay: model.periodPay,
    };

    return totalAccumulator(acc, modelTotal);
  }, defaultTotal);
};

export const getPendingAbsencesForAuthenticatedUser = (absenceRequest: AbsenceLoadRequest) =>
  createSelector(
    getUserAbsence(absenceRequest.userId, absenceRequest.minDate, absenceRequest.maxDate),
    (absences: EnhancedAbsenceModel[]): EnhancedAbsenceModel[] => filterPendingAbsences(absences),
  );

export const getPendingAbsencesForSupervisor = createSelector(
  getAbsenceEnhanced,
  getEmployeeTeamDepartmentsWithoutFlexpool,
  getPermissionState,
  getSelectedDepartmentIds,
  (absences: EnhancedAbsenceModel[], employeeTeamDepartments, permissionState, selectedDepartmentIds) => {
    const minDate = format(subYears(new Date(), 1), 'yyyy-MM-dd');
    const maxDate = format(addYears(new Date(), 2), 'yyyy-MM-dd');

    return filterPendingAbsences(absences)
      .filter((absence) => {
        if (!absence.Employee) {
          return false;
        }
        return employeeTeamDepartments[absence.Employee.id].some((departmentId) =>
          selectedDepartmentIds.includes(departmentId),
        );
      })
      .filter((absence) => absenceInPeriod(minDate, maxDate)(absence))
      .filter((absence) => checkAbsenteePermission(absence, employeeTeamDepartments, permissionState, 'approve'));
  },
);

export const getApprovedOpenEndedAbsencesForSupervisor = createSelector(
  getAbsenceEnhanced,
  getEmployeeTeamDepartmentsWithoutFlexpool,
  getPermissionState,
  getSelectedDepartmentIds,
  (absences: EnhancedAbsenceModel[], employeeTeamDepartments, permissionState, selectedDepartmentIds) => {
    const minDate = format(subYears(new Date(), 1), 'yyyy-MM-dd');
    const maxDate = format(addYears(new Date(), 2), 'yyyy-MM-dd');

    return absences
      .filter((absence) => absence.status === 'Approved')
      .filter((absence) => absence?.open_ended === true)
      .filter((absence) => {
        if (!absence.Employee) {
          return false;
        }
        return employeeTeamDepartments[absence.Employee.id].some((departmentId) =>
          selectedDepartmentIds.includes(departmentId),
        );
      })
      .filter((absence) => absenceInPeriod(minDate, maxDate)(absence))
      .filter((absence) => checkAbsenteePermission(absence, employeeTeamDepartments, permissionState, 'approve'));
  },
);

export const mapAbsenteeDays = (absence: AbsenceModel) => {
  const absenceUnit = absence.absence_unit || 'hours';
  if (!absence.AbsenteeDay) {
    return absence;
  }

  const mappedAbsenteeDay = mapValues(absence.AbsenteeDay, (day) => {
    const hidden = isAbsenceWithoutValueHidden(absence, day);
    let startDate: Date;
    let endDate: Date;
    let endTime: string;
    let startTime: string;

    if (day.partial_day) {
      // Set start date, end date and end time depeding on if the absence is in days or hours
      if (day.from_time) {
        startDate = parse(day.date + ' ' + day.from_time, 'yyyy-MM-dd HH:mm:ss', new Date());
        endDate = endOfDay(startDate);
      } else if (day.until_time) {
        endDate = parse(day.date + ' ' + day.until_time, 'yyyy-MM-dd HH:mm:ss', new Date());
        startDate = startOfDay(endDate);
      } else {
        startDate = parse(day.date + ' ' + day.start_time, 'yyyy-MM-dd HH:mm:ss', new Date());
        const minutes = Math.round(parseFloat(day.hours) * 60);
        endDate = addMinutes(startDate, minutes);
      }
      endTime = format(endDate, 'HH:mm');
      startTime = format(startDate, 'HH:mm');
    } else {
      startDate = parse(day.date, 'yyyy-MM-dd', new Date());
      endDate = endOfDay(startDate);
    }
    return { ...day, startDate, endDate, endTime, startTime, hidden, absence_unit: absenceUnit };
  });

  // When all absenteeDays have hours with the value of 0
  // and the checkbox 'hide days without hours' is checked,
  // the entire absence should be hidden from the schedule.
  const hidden = Object.keys(mappedAbsenteeDay).every((date) => mappedAbsenteeDay[date].hidden);

  return {
    ...absence,
    hidden,
    AbsenteeDay: mappedAbsenteeDay,
  };
};

export const getUserAbsenceForPeriod = (userId: string, minDate: string, maxDate: string, absenceId?: string) =>
  createSelector(getUserAbsence(userId, minDate, maxDate), (absences) => {
    const days = keyBy(dayList(minDate, maxDate), (day) => day);

    return mapValues(days, (day) =>
      absenceForDay(day, absences).filter((absence) => {
        if (absence.status === AbsenceStatus.DECLINED) {
          return false;
        }
        return !(absenceId && absenceId === absence.id);
      }),
    );
  });
