import { UnsafeAction as Action } from '../../interfaces';
import { AbsenceLoadRequest, LoadAbsenceSuccessAction } from './absence.model';

const ACTION_PREFIX = '[Absence]';

export const AbsenceActionType = {
  LOAD: `${ACTION_PREFIX} Load for stats`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load for stats success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load for stats failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  FETCH: `${ACTION_PREFIX} Fetch`,
  FETCH_SUCCESS: `${ACTION_PREFIX} Fetch Success`,
  FETCH_FAILED: `${ACTION_PREFIX} Fetch Failed`,

  REMOVE: `${ACTION_PREFIX} Remove`,
  REMOVE_SUCCESS: `${ACTION_PREFIX} Remove Success`,
  REMOVE_FAILED: `${ACTION_PREFIX} Remove Failed`,
};

export class AbsenceAction {
  public static load(data: AbsenceLoadRequest): Action {
    return {
      type: AbsenceActionType.LOAD,
      payload: data,
    };
  }

  public static loadSuccess(data: AbsenceLoadRequest, absence): LoadAbsenceSuccessAction {
    return {
      type: AbsenceActionType.LOAD_SUCCESS,
      payload: absence,
      requestData: data,
    };
  }

  public static loadFailed(err): Action {
    return {
      type: AbsenceActionType.LOAD_FAILED,
      payload: err,
    };
  }

  public static add(correctionData): Action {
    return {
      type: AbsenceActionType.ADD,
      payload: correctionData,
    };
  }

  public static addSuccess(correctionResponse): Action {
    return {
      type: AbsenceActionType.ADD_SUCCESS,
      payload: correctionResponse,
    };
  }

  public static addFailed(err): Action {
    return {
      type: AbsenceActionType.ADD_FAILED,
      payload: err,
    };
  }

  public static update(correctionData): Action {
    return {
      type: AbsenceActionType.UPDATE,
      payload: correctionData,
    };
  }

  public static updateSuccess(correctionResponse): Action {
    return {
      type: AbsenceActionType.UPDATE_SUCCESS,
      payload: correctionResponse,
    };
  }

  public static updateFailed(id, err): Action {
    return {
      type: AbsenceActionType.UPDATE_FAILED,
      payload: {
        id,
        err,
      },
    };
  }

  public static fetch(id: any) {
    return {
      type: AbsenceActionType.FETCH,
      payload: id,
    };
  }

  public static fetchSuccess(response) {
    return {
      type: AbsenceActionType.FETCH_SUCCESS,
      payload: response,
    };
  }

  public static fetchFailed(id: string, err) {
    return {
      type: AbsenceActionType.FETCH_FAILED,
      payload: {
        id,
        err,
      },
    };
  }

  public static remove(id: string, userId: string, departmentIds: string[]) {
    return {
      type: AbsenceActionType.REMOVE,
      payload: {
        id,
        userId,
        departmentIds,
      },
    };
  }

  public static removeSuccess(id: string) {
    return {
      type: AbsenceActionType.REMOVE_SUCCESS,
      payload: id,
    };
  }

  public static removeFailed(id: string, err) {
    return {
      type: AbsenceActionType.REMOVE_FAILED,
      payload: {
        id,
        err,
      },
    };
  }
}
