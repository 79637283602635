import { Injectable } from '@angular/core';
import { arrayOf, normalize } from 'normalizr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { objectToSearchParams } from '../../../api/api.helper';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { UnsafeAction as Action } from '../../interfaces';
import { assignSchemaEntity } from '../../shared/assign';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { AbsenceSchema } from '../../shared/schemas';
import {
  AbsenceExpectedRequest,
  AbsenceExpectedResponse,
  AbsenceLoadRequest,
  AbsenceMarkAsReturnRequest,
  AbsenceReviewCheck,
} from './absence.model';

export interface ExpectedAbsenceHoursRequest {
  user_id: string;
  partial_day: boolean;
  startdate: string;
  enddate?: string;
  start_time?: string;
  end_time?: string;
  absence_type_id: string;
}

@Injectable()
export class AbsenceApi {
  private endpoint = 'absentees/';

  public constructor(private gateway: ApiGateway) {}

  public load(data: AbsenceLoadRequest, dispatchStart?: Action): Observable<any> {
    data = {
      ...data,
      include: 'AbsenteeDay',
    };
    const search = objectToSearchParams(data);

    return this.gateway
      .get(
        this.endpoint,
        {
          params: search,
        },
        dispatchStart,
      )
      .pipe(
        map((res) => reformatListResponse('Absentee', res)),
        map((res) => normalize(res, arrayOf(AbsenceSchema), { assignEntity: assignSchemaEntity })),
      );
  }

  public add(data, dispatchStart?: Action): Observable<any> {
    return this.gateway.post(this.endpoint, data, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Absentee', res)),
      map((res) => normalize(res, AbsenceSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public getInfo(data): Observable<any> {
    return this.gateway.post(this.endpoint + 'info', data);
  }

  /**
   * Call bulk update absentee API
   *
   * @returns {Observable<any>}
   */
  public bulkUpdateAbsentees(absenceRequest) {
    return this.gateway.post(this.endpoint + 'bulk', absenceRequest);
  }

  public update(id, data, dispatchStart?: Action): Observable<any> {
    return this.gateway.put(this.endpoint + id, data, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Absentee', res)),
      map((res) => normalize(res, AbsenceSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public fetch(absenceId, dispatchStart?: Action): Observable<any> {
    return this.gateway.get(this.endpoint + absenceId, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Absentee', res)),
      map((res) => normalize(res, AbsenceSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public remove(absenceId, dispatchStart?: Action): Observable<any> {
    return this.gateway.delete(this.endpoint + absenceId, undefined, dispatchStart);
  }

  public review(absenceId, endDate: string | null, dispatchStart?: Action): Observable<AbsenceReviewCheck[]> {
    return this.gateway.get(
      this.endpoint + absenceId + '/review',
      endDate ? { params: { endDate } } : undefined,
      dispatchStart,
    );
  }

  public getExpected(absence: AbsenceExpectedRequest): Observable<AbsenceExpectedResponse> {
    return this.gateway.post(`${this.endpoint}expected`, absence);
  }

  // TODO stricter return type made difficult because of usage of normalizr
  public markAsReturned(requestData: AbsenceMarkAsReturnRequest, dispatchStart?: Action): Observable<any> {
    const { id, ...payload } = requestData;
    return this.gateway.put(`${this.endpoint}${id}/return`, payload, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Absentee', res)),
      map((res) => normalize(res, AbsenceSchema, { assignEntity: assignSchemaEntity })),
    );
  }
}
