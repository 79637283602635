import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { reformatListResponse } from '../../shared/entity.helper';
import { PermissionSchema } from '../../shared/schemas';
import { arrayOf, normalize } from 'normalizr';
import { assignSchemaEntity } from '../../shared/assign';

@Injectable()
export class PermissionApi {
  private endpoint = 'permissions/';

  constructor(private gateway: ApiGateway) {}

  load(): Observable<any> {
    const params = { limit: '250' };

    return this.gateway.get(this.endpoint, { params: params }).pipe(
      map((res) => reformatListResponse('Permission', res)),
      map((data) => normalize(data, arrayOf(PermissionSchema), { assignEntity: assignSchemaEntity }))
    );
  }

  savePermisions(permissions: { [permissionId: string]: string[] }): Observable<any> {
    return this.gateway.post(this.endpoint + 'batch', permissions);
  }
}
